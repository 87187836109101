.dashboard-list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(8, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	gap: 1rem 1rem;
}

.cards-btn:nth-of-type(1) {
	grid-area: 1 / 1 / 5 / 5;	height:100%;
}
.cards-btn:nth-of-type(2) {
	grid-area: 5 / 1 / 9 / 3;
	height:100%;
}
.cards-btn:nth-of-type(3) {
	grid-area: 5 / 3 / 9 / 5;
	height:100%;
}
.dashboard-item:nth-of-type(1) {
	grid-area: 1 / 1 / 5 / 4;
	height:100%;
}

.dashboard-item:nth-child(2) {
	grid-area: 1 / 4 / 3 / 7;
	height:100%;
}

.dashboard-item:nth-child(3) {
	grid-area: 3 / 4 / 5 / 7;
	height:100%;
}

@media only screen and (max-width: 800px) {
	.dashboard-list {
		display: flex;
		flex-direction: column;
	}
	.logo-ccif{
		width:75px!important;
	}
	.dashboard-item {
		height: 250px!important;;
	}
	.cards-btn{
		position: relative;
		min-height:0!important;
	}
}


.cards-btn{
	position: relative;
    min-height: 300px;
	z-index: 1;
}
.logo-ccif{
	width:200px;
	position:absolute;
	bottom:16px;
	left:16px;
}
