.content {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 10px;
    min-height: 100vh;
    background-image: url('../assets/img/background_CCIF.png');
}

.test {
    display: flex;
}

.main-content {
    width: 100%;
    border-radius: 10px;
    overflow: scroll;
}

.main-content-map {
    overflow: hidden;
    display: flex;
    height: calc(100%);
    width: 100%;
    position: relative;
    border-radius: 10px
}

main {
    position: relative;
    width: 100%;
    height: 100%;

    padding: 0 45px 50px;
}

.c-header {
    width: 100%;
    align-items: center;
    // padding: 32px 0px 8px 0;
    margin-bottom: 16px;
    margin-top:30px;
}

.App {
    display: flex;
    width: 100vw;
    flex-direction: column;
    height: -webkit-fill-available;
}

@media only screen and (max-width: 800px) {

    main,
    .main-content {
        overflow: inherit;
    }

    .App {
        flex-direction: column;
    }

    .cards {
        margin-bottom: 16px;
        height: 100%;
    }

    .c-header {
        margin-top: 160px;
    }

}