@font-face {
    font-family: 'Fopen Midi Black';
    src: url('../assets/fonts/Fopen-MidiBold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Fopen Midi Regular';
    src: url('../assets/fonts/Fopen-MidiRegular.ttf') format('truetype');
  }
.link-sidenav{
    font-family: 'Fopen Midi Regular';
    font-size: 14px;
    color: #314380;;
    text-decoration: none;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    ::-webkit-scrollbar {
        display: none;
      }
      
}
.bandeau{
    background: #314380;;
    top:0;
    z-index: 100;
    padding: 20px 16px;
    width:100%;
    left:0;
    display:flex;
    align-items: center;
}
.bandeau-txt{
    font-size: 0.9rem;
}
.fill-gap{
    height: 30px;
    background: white;
    position: fixed;
    width: 100%;
    top: -10px;
    display:none;
}
body, .App{
    background: white;
}
.gap-filler{
    height:500px;
    background:red;
    width:100%;
    position:fixed;
    top: -50px;
    z-index:-1;
}
select, option{
    font-family: 'Fopen Midi Regular';
}


.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}
input{
    font-family: 'Fopen Midi Regular';
}
html {
    font-size: 16px;
}
.flex-column{
    display:flex;
    flex-direction: column;
    width:100%;
}
.relative{
    position:relative;
}
hr{
    border: 1px solid #ECECEC;
  }
.main-content-map .container{
    overflow: scroll;
    position: relative;
    padding: 0;
}
.container{
    border: 2px solid #ECECEC;
    border-radius: 10px;
    padding:1rem;
    background:white;
    width:100%;
  }


.mapboxgl-control-container{
    display:none;
}


.App-height{
    height: 100%!important;
}

a {
    all: unset;
    cursor: pointer;
}

button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}


@media screen and (max-width: 800px) {
    .fill-gap{
        display: block
    }
    .bandeau-txt{
        font-size: 0.8rem!important;
    }
}