.c-sidebar,
.c-sidebar--closed {
    height: calc(100vh - 80px);
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top:80px;
    padding:16px 0;
    position:relative;
    background: white;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    hr {
        margin: 12px 0;
    }
}
.bottom-footer{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.contact-infos{
    padding:0 16px 0 48px;
    display:flex;
    flex-direction: column;
    gap:0.5em;
}
.contact-infos-mobile{
    margin-bottom: 32px;
    padding:0 16px 0 48px;
    display:flex;
    flex-direction: column;
    gap:0.5em;
}
.contact-infos-closed{
    display:none;
}
.c-sidebar--closed .topbar-logo{
    padding:16px;

}

.c-sidebar--closed .c-sidebar__item {
    justify-content: center;
    padding: 8px 32px;

}
.topbar-logo{
   width:100px;
   margin-right: 16px;
}
.c-sidebar__item {
    margin: 8px 16px;
    padding: 8px 32px;
    border-radius: 5px;
    display: flex;
    height:36px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.c-sidebar__item__icon {
    height: 16px;
}
.logo-all{
    width:120px;
    margin:0 16px;
}
.logo-all-closed{
    width: 80px;
    margin: 0 16px;
}
.c-sidebar__item__text {
    white-space: nowrap;
    margin-left: 16px;
    font-size: 14px;
    font-family: 'Fopen Midi Black';
}

.c-sidebar__item__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 20px;
}

.c-sidebar__item__text--closed {
    visibility: hidden;
    display: none;
}
.sidenav-logo{
    width:100%;
    display:flex;
    justify-content: center;
    margin-top:48px;
}

.c-sidebar__btn {
    width:100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding:16px;
}

@media only screen and (max-width: 800px) {

    .c-sidebar,
    .c-sidebar--closed {
        display: none;
        height: auto;
    }

    .c-sidebar__item__text,
    .c-sidebar__btn {
        display: none;
    }
    
    hr {
        display: none;
    }
}


.language{
    display:flex;
    justify-content: center;
    font-family: 'Fopen Midi Black';
    align-items: center;

}
.language-mobile{
    display:flex;
    justify-content: center;
    align-items: center;
    font-family: 'Fopen Midi Black';
}

.language-selection{
    color: rgb(230,230,230);
    font-size:1rem;
    align-items:center;
    background:none;
    border: none;
    font-family: 'Fopen Midi Black';
    cursor: pointer;
    display: flex;
    gap: 15px;
}
.language-separator{
    width:1px;
    height:16px;
    background:black;
    margin: 8px;
}

.language-selection.active{
    color: #314380;
}