.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../../assets/img/mobile-standby-bcgrnd.png') no-repeat center center;
  background-size: cover;

  .maintenance-content {
    max-width: 390px;
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .maintenance-heading {
      margin-bottom: 20px;
    }

    .maintenance-text {
      margin-bottom: 30px;
    }

    .maintenance-link {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}