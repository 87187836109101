.c-topbar{
    position: fixed;
    z-index: 100;
    margin-top:80px;
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items:center;
    padding: 30px 45px 10px 45px;
    background-color: white;
    border-bottom: 1px solid #EBECF0
}
.right-menu{
    display:flex;
    gap:3rem;
}
.menu-icon{
    width:18px;
    display:none;
}

.topbar-text{
    align-items: center;
    display:flex;
    height:100%;
}

.topbar-bar{
    margin-right:16px;
    width:1px;
    height:60%;
    background: rgba(0,0,0,0.1)
}

.topbar_input{
    width: 40%;
}

@media only screen and (max-width: 860px) {
    .user_info{
        display: none !important;
    }
    .topbar_input{
        width: unset;
    }
    .language_button{
        display: none !important;
    }
    .menu-icon{
        display:block;
    }
}