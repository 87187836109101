.btn {
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    border: none;
    outline: none;
    width: fit-content;
    white-space: nowrap;
}
.btn-disable{
    opacity: 50%;
    pointer-events: none;
}
.btn-link{
    padding:0;
    background: transparent;
    margin-left:4px;
}
.modification{
    width:100%;
    padding:24px 8px;
    display:flex;
    justify-content: end;
}
.btn-square{
    border-radius: 8px;
    background-color: #314380;
    padding: 0.5rem 2rem;
}
.btn-transparent{
    padding: 0.5rem 2rem;

    background:none;
}
.btn-primary {
    background-color: #314380;
    padding: 15px 20px;
}
.btn-orange{
    background-color: #FF6633;
    padding: 10px 28px;
}
.btn-grey{
    background-color: #ADB8CC;
    z-index: 1;
    padding: 10px 20px;
    box-shadow: 0px 2px 5px 0px #29CC391A;
}

.btn-landing {
    background-color: white;
    padding: 0.5rem 2rem;
    color : #314380;
}

.btn-landing p{
    color : #314380;
    font-family: 'Fopen Midi Black';

}

.btn-primary p{
    color: #F8F8F8;
    font-weight: 900;
}

.btn-secondary {
    background-color: #F8F8F8;
    padding: 16px 32px;
}
.btn-secondary p{
    color: #6584CC;
    font-weight: 400;
}

.btn-outline, 
.btn-outline-secondary {
    padding: 16px 0;
    background-color: transparent;

}

.btn-outline p {
    font-weight: 400;
    padding: 0;
}

.btn-outline-secondary p {
    color: #6584CC;
    font-weight: 400;
    padding: 0; 
}

.btn-row .icon {
    margin-left: 8px;
    margin-right: 0;
}

.btn-row-reverse {
    flex-direction: row-reverse;
}

.btn-row-reverse .icon {
    margin-right: 8px;
    margin-left: 0;
}

