.link-more p{

    color:#6584cc!important;
}
// ————————————————————————————————| Global settings of Footer
.c-footer {
    padding: 0 45px 26px;

    &__text-cotainer{
        margin-top: 20px;
        padding-left: 50px;
        & > a {
            display: block;
            margin-bottom: 20px;
        }
    }
}

.mb-20{
    margin-bottom: 20px;
}

.btn-more-infos{
    margin-bottom:16px;
    text-decoration: underline;
    color :#6584CC;
    white-space: wrap;
    text-align: left;
}
// ————————————————————————————————| Ordering Links
.c-footer {
    &__links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 5%;
        
        .c-footer__logo {
            width: 20%;
        }
        .c-footer__contact {
            width: 20%;
        }
        .c-footer__menu {
            width: 20%;
        }
        .c-footer__app {
            width: 40%;
        }
    }
}
// ————————————————————————————————| Single Objects
// ——————————| LOGO
.ccif-img, .all-img{
    cursor: pointer;
}
.all-img{
}
// ——————————| CONTACT
.c-footer__contact {
    &__number,
    &__email {
        display: flex;
        flex-direction: row;
        gap: 6px;
    }
}
.c-footer__logo_items{
    margin-bottom: 24px;
}
.c-footer__logo-img{
    align-items: center;
    display:flex;
    gap:3rem;
}
// ——————————| MENU 
.c-footer__menu > p:nth-child(n + 2) {
    margin-bottom: 24px;
}

// ——————————| APP DOWNLOAD
.c-footer__app {
    &__button {
        margin-top: 64px;
    }
}


// ————————————————————————————————| Languages
.c-footer__languages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    border-top: 1px solid rgba(248, 248, 248, .2);
    // opacity: 20%;
    // width: 92%;
    .btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

    }
}


//! //////////////////////////////////////////////////// !//
@media screen and (max-width: 1650px) {
    // ————————————————————————————————| Global settings of Footer
    .c-footer {
        width: 100vw;
        &__links {
            height: auto;
        }
        &__languages {
            height: 100px;
        }
    }

    // ————————————————————————————————| Ordering Links
    .c-footer {
        &__links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 5%;
            row-gap: 64px;
            flex-wrap: wrap;
            .c-footer__logo {
                width: 30%;
            }
            .c-footer__contact {
                width: 30%;
            }
            .c-footer__menu {
                width: 30%;
            }
            .c-footer__app {
                width: 100%;
            }
        }
    }   
}


//! //////////////////////////////////////////////////// !//
@media screen and (max-width: 1400px) {
    // ————————————————————————————————| Global settings of Footer
    .c-footer {
        width: 100vw;
        &__links {
            height: auto;
        }
        &__languages {
            height: 100px;
        }
    }

    // ————————————————————————————————| Ordering Links
    .c-footer {
        &__links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 5%;
            row-gap: 96px;
            flex-wrap: wrap;
            .c-footer__logo {
                order: 4;
                width: 45%;
            }
            .c-footer__contact {
                order: 3;
                width: 45%;
            }
            .c-footer__menu {
                order: 1;
                width: 45%;
            }
            .c-footer__app {
                order: 2;
                width: 45%;
            }
        }
    }  
}


//! //////////////////////////////////////////////////// !//
@media screen and (max-width: 1200px) {
    // ————————————————————————————————| Global settings of Footer
    .c-footer {
        width: 100vw;
        &__links {
            height: auto;
        }
        &__languages {
            height: 100px;
        }
    }

    // ————————————————————————————————| Ordering Links
    .c-footer {
        &__links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 5%;
            row-gap: 96px;
            flex-wrap: wrap;
            .c-footer__logo {
                order: 2;
                width: 30%;
            }
            .c-footer__contact {
                order: 3;
                width: 30%;
            }
            .c-footer__menu {
                order: 4;
                width: 30%;
            }
            .c-footer__app {
                order: 1;
                width: 100%;
            }
        }
    } 
}



//! //////////////////////////////////////////////////// !//
@media screen and (max-width: 900px) {
    .c-footer__logo-img{
        align-items: start;
        display:flex;
        flex-direction: column;
        gap:3rem;
    }
    // ————————————————————————————————| Global settings of Footer
    .c-footer {
        width: 100vw;
        &__links {
            height: auto;
        }
        &__languages {
            height: 100px;
        }
    }

    // ————————————————————————————————| Ordering Links
    .c-footer {
        &__links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 5%;
            row-gap: 96px;
            flex-wrap: wrap;
            .c-footer__logo {
                order: 2;
                width: 100%;
            }
            .c-footer__contact {
                order: 3;
                width: 100%;
            }
            .c-footer__menu {
                order: 4;
                width: 100%;
            }
            .c-footer__app {
                order: 1;
                width: 100%;
            }
        }
    } 
    // ————————————————————————————————| Single Objects
    // ——————————| APP DOWNLOAD
    .c-footer__app__button > * {
            margin-bottom: 24px;
    }
}