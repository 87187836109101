.login-container{
    display:flex;
    justify-content: center;
    width:100vw;
    position: relative;
}
.info-message{
    position: absolute;
    top:120px;
}

.welcome-message{
    margin-bottom:48px;
    max-width: 400px;
}
.info-section{
    padding-left: 48px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;
}
.form-section{
    position: relative;
    background: #F7F8FA;
    min-height: 100vh;
    width: 100vw;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        min-height: 100vh;
        width: 100vw;
        background-image: url("../../assets/img/large_background.png");
    }
}

.title{
    margin-bottom: 40px;
    text-align: center;
}

.form-section form{
    width:400px;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.btns-box{
    display:flex;
}

.btns-box p{
    text-wrap: nowrap;
}

.mb-3{
    width:100%;
}

.form-section form button{
    width:100%;
    display:flex;
    justify-content: center;
}

.info-section img{
    max-height: 600px;
    width: auto;
    position: absolute;
}


@media screen and (max-width: 950px) {
        .form-section{
            width: 100%;
            gap: 0.5rem;
        }
        .info-section {
            display: none;
        }
        .welcome-message {
            margin-bottom:0;
            width: 100%;
            padding: 0 32px;
        }
        .form-section form{
            width:100%;
           padding:16px;
        }
        
        // .login-container{
        //     height:100vh;
        //     width: 100vw;
        //     padding: 16px 0px 16px 0px;
        //     height: auto;
        // }
       
}